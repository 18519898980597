<template>
    <div>
        <!--details packages-->
        <b-card>
            <template #header>
                <div class="d-flex justify-content-start align-items-center">
                    <unicon
                        name="bookmark"
                        class="mr-1"
                        style="fill: #6e6b7b"
                    ></unicon>
                    <h4 class="m-0">معلومات الحزمة</h4>
                </div>
                <div class="d-flex justify-content-start align-items-center">
                    <b-badge
                        pill
                        :variant="packageDto.isHidden ? 'danger' : 'success'"
                    >
                        {{ packageDto.isHidden ? " ملغية" : " فعالة" }}
                    </b-badge>
                </div>
            </template>
            <validationObserver ref="sellPointForm">
                <b-row>
                    <b-col lg="4" md="6">
                        <ek-input-text
                            name=" اسم الحزمة"
                            label="اسم الحزمة "
                            :rules="[
                                {
                                    type: 'required',
                                    message: 'اسم الحزمة مطلوب',
                                },
                            ]"
                            v-model="packageDto.name"
                        ></ek-input-text>
                    </b-col>

                    <b-col lg="4" md="6" class="mt-1">
                        <b-form-group
                            label="نوع الحزمة"
                            v-slot="{ ariaDescribedby }"
                        >
                            <b-form-radio-group
                                :options="
                                    packageOptions.map((el) => ({
                                        value: el.id,
                                        text: el.name,
                                    }))
                                "
                                :aria-describedby="ariaDescribedby"
                                name="radio-options"
                                v-model="packageDto.packageType"
                                disabled
                            >
                            </b-form-radio-group>
                        </b-form-group>
                    </b-col>

                    <b-col lg="4" md="6">
                        <ek-input-select
                            name="الفرع"
                            label=" تابع ل جامعة - كلية - فرع "
                            placeholder="جامعة - كلية - فرع"
                            :options="departmentsSubjectsOptions"
                            v-model="packageDto.departmentsIds"
                            @change="resetSelect"
                        >
                        </ek-input-select>
                    </b-col>

                    <b-col lg="4" md="6">
                        <ek-input-select
                            name="المادة"
                            v-if="packageDto.departmentsIds"
                            v-model="packageDto.subjectIds"
                            :options="
                                departmentsSubjectsOptions
                                    ? departmentsSubjectsOptions.find(
                                          (d) =>
                                              d.id == packageDto.departmentsIds
                                      ).subjects
                                    : []
                            "
                            multiple
                            label=" تابع ل سنة - فصل - مادة "
                            placeholder="تابع ل سنة - فصل - مادة "
                            @change="resetCourse"
                        >
                        </ek-input-select>
                    </b-col>

                    <b-col
                        lg="4"
                        md="6"
                        v-if="packageDto.packageType == 'Course'"
                    >
                        <ek-input-select
                            name="الكورس"
                            v-model="packageDto.courseIds"
                            :options="filteredCourses"
                            multiple
                            label=" تابع ل كورس معين  "
                            placeholder="تابع ل كورس معين  "
                        >
                        </ek-input-select>
                    </b-col>

                    <b-col lg="4" md="6">
                        <div class="d-flex">
                            <b-col lg="6" md="6">
                                <ek-input-text
                                    readonly
                                    v-model="packageDto.codesCount"
                                    name="الرموز"
                                    label="عدد الرموز"
                                >
                                </ek-input-text>
                            </b-col>
                            <b-col lg="6" md="6">
                                <ek-input-text
                                    readonly
                                    v-model="packageDto.codesActiveCount"
                                    name="المشتركين"
                                    label="عدد المشتركين"
                                ></ek-input-text>
                            </b-col>
                        </div>
                    </b-col>

                    <b-col lg="4" md="6">
                        <ek-input-text
                            name="price"
                            label="كلفة الحزمة"
                            placeholder="ادخل التكلفة"
                            v-model="packageDto.price"
                            :rules="[
                                { type: 'required', message: 'التكلفة مطلوب' },
                            ]"
                        ></ek-input-text>
                    </b-col>

                    <b-col lg="4" md="6">
                        <div class="d-flex w-100">
                            <b-col cols="12" md="6">
                                <ek-date-picker
                                    readonly
                                    v-model="packageDto.dateCreated"
                                    name="التاريخ"
                                    label="تاريخ التوليد "
                                >
                                </ek-date-picker>
                            </b-col>
                            <b-col cols="12" md="6">
                                <ek-date-picker
                                    readonly
                                    v-model="packageDto.endDate"
                                    name="التاريخ"
                                    label="تاريخ الانتهاء "
                                >
                                </ek-date-picker>
                            </b-col>
                        </div>
                    </b-col>

                    <b-col lg="4" md="6">
                        <ek-input-select
                            name="النوع"
                            label="حالة الحزمة"
                            placeholder="  الحزمة فعالة"
                            :options="statusDetailsPackage"
                            v-model="packageDto.isHidden"
                        >
                        </ek-input-select>
                    </b-col>
                    <b-col lg="12" md="12">
                        <ek-input-textarea
                            name="description"
                            placeholder="ادخل  وصف الحزمة "
                            label=" وصف الحزمة  "
                            v-model="packageDto.description"
                        >
                        </ek-input-textarea>
                    </b-col>
                </b-row>
            </validationObserver>
        </b-card>

        <div class="d-flex justify-content-end align-items-center mb-2 gap-4">
            <!--dialog add codes-->
            <div>
                <ValidationObserver ref="codePackage">
                    <b-form>
                        <div
                            class="d-flex justify-content-end align-items-center gap-4"
                        >
                            <ek-input-text
                                class="mx-1"
                                v-model="searchVal"
                                name="ابحث عن رمز محدد"
                                placeholder="ابحث عن رمز محدد"
                                @input="filterSearch"
                            >
                            </ek-input-text>

                            <ek-dialog
                                ref="addDialog"
                                title="إضافة رمز "
                                size="md"
                                btnText=" رمز جديد "
                                @ok="submit()"
                                @close="reset"
                            >
                                <template #body>
                                    <ek-input-select
                                        name="حزمة "
                                        label="اختر حزمة"
                                        placeholder=" اختر حزمة معينة"
                                        :options="packageList"
                                        v-model="codesDto.packageId"
                                    >
                                    </ek-input-select>

                                    <ek-input-text
                                        type="number"
                                        name="discount"
                                        placeholder="ادخل  الحسم   "
                                        label="  الحسم  "
                                        v-model="codesDto.discount"
                                    ></ek-input-text>
                                    <b-form-group
                                        label="نوع تاريخ الانتهاء "
                                        v-slot="{ DateType }"
                                    >
                                        <b-form-radio-group
                                            :aria-describedby="DateType"
                                            name="radio"
                                            v-model="date"
                                        >
                                            <b-form-radio value="dateDay"
                                                >موعد الانتهاء
                                                (بالايام)</b-form-radio
                                            >
                                            <b-form-radio value="dateTime"
                                                >موعد الانتهاء
                                                (بالتاريخ)</b-form-radio
                                            >
                                        </b-form-radio-group>
                                    </b-form-group>
                                    <ek-input-text
                                        v-if="date == 'dateDay'"
                                        type="number"
                                        name="period"
                                        placeholder="حدد موعد الإنتهاء "
                                        label="موعد الانتهاء  (بالايام)"
                                        v-model="codesDto.period"
                                    ></ek-input-text>

                                    <ek-date-picker
                                        v-if="date == 'dateTime'"
                                        name="date"
                                        placeholder="حدد موعد انتهاء الرمز"
                                        label=" حدد موعد انتهاء الرمز"
                                        v-model="codesDto.endDate"
                                    ></ek-date-picker>
                                    <ek-input-text
                                        readonly
                                        v-if="codesDto.packageId"
                                        name="price"
                                        label="التكلفة"
                                        :value="
                                            packageList.find(
                                                (el) =>
                                                    el.id == codesDto.packageId
                                            ).price
                                        "
                                    ></ek-input-text>
                                </template>
                            </ek-dialog>
                        </div>
                    </b-form>
                </ValidationObserver>
            </div>
            <div v-if="codeId != ''" class="mx-2">
                <ValidationObserver ref="code">
                    <b-form>
                        <ek-dialog
                            ref="addStudentDialog"
                            title="إضافة طالب"
                            size="md"
                            btnText=" إضافة طالب "
                            @ok="ActiveStudentCode(activeCode, studentId)"
                            @close="resetData"
                        >
                            <template #body>
                                <ek-input-select
                                    placeholder="اسم الطالب"
                                    label="  اسم الطالب  "
                                    name="  اسم الطالب  "
                                    v-model="studentId"
                                    :options="studentName"
                                    :clearable="true"
                                    :rules="[
                                        {
                                            type: 'required',
                                            message: 'اسم الطالب مطلوب',
                                        },
                                    ]"
                                ></ek-input-select>

                                <ek-input-select
                                    name="كود "
                                    label="اختر كود"
                                    placeholder=" اختر كود معينة"
                                    :options="packageDto.codes"
                                    textLabel="code"
                                    valueLabel="code"
                                    v-model="activeCode"
                                    :clearable="true"
                                    :rules="[
                                        {
                                            type: 'required',
                                            message: 'الكود مطلوب',
                                        },
                                    ]"
                                >
                                </ek-input-select>
                            </template> </ek-dialog></b-form
                ></ValidationObserver>
            </div>
        </div>
        <!--table codes-->
        <ek-table
            ref="customTableComponent"
            :columns="columnsCode"
            :items="filterCodes"
            @delete-selected="deleteCode"
            @selected-rows="handleSelectedRows"
        >
            <template slot="items.endDate" slot-scope="{ value }">
                <span>{{
                    value ? new Date(value).toLocaleDateString() : "_"
                }}</span>
            </template>

            <template slot="items.startDate" slot-scope="{ value }">
                <span>{{
                    value ? new Date(value).toLocaleDateString() : "_"
                }}</span>
            </template>

            <template slot="items.isActive" slot-scope="{ value }">
                <StatusBadge :options="statusCode" :value="value"></StatusBadge>
            </template>
        </ek-table>
    </div>
</template>
<script>
import { mapState, mapActions, mapGetters } from "vuex";
import StatusBadge from "@/app/global/components/StatusBadge.vue";

export default {
    components: {
        StatusBadge,
    },
    props: {
        id: String,
    },
    data: () => ({
        searchVal: "",
        activeCode: "",
        studentId: "",
        date: "dateTime",
        codeId: "",
    }),
    computed: {
        ...mapState({
            universityName: (state) => state.university.universityName,
            unActivateCode: (state) => state.codes.unActivateCode,
            studentName: (state) => state.codes.studentName,
            packageOptions: (state) => state.codes.packageOptions,
            packageList: ({ codes }) => codes.packageList,
            packageDto: (state) => state.codes.packageDto,
            codesDto: (state) => state.codes.codesDto,
            columnsCode: (state) => state.codes.columnsCode,
            statusCode: ({ codes }) => codes.statusCode,
            statusDetailsPackage: (state) => state.codes.statusDetailsPackage,
            departmentsSubjectsOptions: ({ courses }) =>
                courses.departmentsSubjectsOptions,
            filteredCourses: ({ codes, courses }) =>
                courses.baseCoursesList.filter((co) =>
                    codes.packageDto.subjectIds.includes(co.subjectId)
                ),
        }),
        ...mapGetters(["filterCodes"]),
    },
    methods: {
        ...mapActions([
            "getPackageDetails",
            "addCode",
            "getPackageList",
            "deleteCode",
            "getDepartmentsWithSubjects",
            "GetAllUnActivatedCodes",
            "ActivateStudentCode",
            "getStudentsName",
        ]),
        filterSearch() {
            this.$store.commit("Filter_Codes", this.searchVal);
        },
        formData(target) {
            if (target == false) return "فعالة";
            else "ملغية";
        },
        resetSelect() {
            this.packageDto.subjectIds = "";
            this.packageDto.courseIds = "";
        },
        resetCourse() {
            this.packageDto.courseIds = [];
        },

        //submit add codes
        submit() {
            this.$refs.codePackage.validate().then((success) => {
                if (success) {
                    this.addCode({
                        ...this.codesDto,
                        period: this.codesDto.endDate
                            ? 0
                            : +this.codesDto.period,
                    }).then(() => {
                        this.codesDto.endDate = null;
                        this.codesDto.endDate = 0;
                    });
                    this.$refs.addDialog.close();
                    this.$refs.codePackage.reset();
                }
            });
        },
        setSearch(query) {
            this.$store.commit("Set_Search_Dto", {
                keys: ["code", "studentName", "createdBy"],
                query,
            });
        },
        reset() {
            this.$refs["codePackage"].reset();
            Object.assign(this.codesDto, {
                packageId: "",
                discount: "",
                period: 0,
                endDate: null,
            });
        },
        resetData() {
            this.$refs["codePackage"].reset();
            // this.activeCode = "";
            this.studentId = "";
        },
        ActiveStudentCode(code, id) {
            this.$refs.code.validate().then((success) => {
                if (success) {
                    const activateCode = code;
                    const studentId = id;
                    this.$store.dispatch("ActivateStudentCode", {
                        activateCode,
                        studentId,
                    });
                    this.$refs.addStudentDialog.close();
                    this.$refs.code.reset();
                }
            });
        },
        handleSelectedRows(selectedRows) {
            if (selectedRows && selectedRows.length == 1) {
                if (!selectedRows[0].isActive) {
                    // Use ! to check if isActive is false
                    this.codeId = selectedRows[0].id;
                    this.activeCode = selectedRows[0].code;
                    console.log("Selected Rows:", selectedRows[0].id);
                }
            } else {
                // Reset selected rows
                this.codeId = "";
                console.log("No Rows Selected");
            }
        },
    },

    created() {
        this.GetAllUnActivatedCodes();
        this.getPackageDetails(this.id);
        this.getStudentsName();
        this.getPackageList();
        this.getDepartmentsWithSubjects();
        this.getBaseCoursesList();
    },
};
</script>
